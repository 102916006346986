import axios from 'axios';
import sha1 from 'js-sha1';
import config from '../config';
import { AppsData, ConfigData, UserData } from '../types';

axios.interceptors.response.use(
  function (response) {
    if (response && response.data && response.data.status === 401) {
      localStorage.removeItem('user');
      window.location.reload();
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export const login = async (loginId: string, password: string) => {
  const res = await axios.post<UserData>(
    config.api.url + 'api/user/login',
    { loginId, password: sha1(password).toUpperCase() },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

  return res;
};

export const getAppList = async (
  status?: string,
  sport?: string,
  search?: string,
) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  const res = await axios.get<AppsData>(config.api.url + 'api/app/list', {
    headers: {
      Accept: 'application/json',
      Authorization: `Basic ${user.accessToken}`,
    },
    params: { status, sport, search },
  });

  return res;
};

export const getConfigList = async () => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  const res = await axios.get<ConfigData>(config.api.url + 'api/config/list', {
    headers: {
      Accept: 'application/json',
      Authorization: `Basic ${user.accessToken}`,
    },
  });

  return res;
};

export const updateConfigList = async (data: ConfigData) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  const res = await axios.post<ConfigData>(
    config.api.url + 'api/config/save',
    data,
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Basic ${user.accessToken}`,
      },
    },
  );

  return res;
};
