import {
  AppsData,
  AppData,
  PushData,
  ReducerData,
  ReduxActionData,
} from '../../types';
import { DATA_ACTIONS } from '../actions/data';

const initialState: ReducerData = {
  list: {},
  group: [],
};

const ACTION_HANDLERS: {
  [key: string]: (
    previousState: ReducerData,
    action: ReduxActionData<any>,
  ) => ReducerData;
} = {
  [DATA_ACTIONS.SET_APPLIST]: (
    previousState: ReducerData,
    action: ReduxActionData<AppsData>,
  ) => {
    const list = action.payload.data.reduce(
      (result: { [key: string]: AppData }, item) => {
        result[item.appId] = item;
        return result;
      },
      {},
    );

    const group = action.payload.group;
    return {
      ...previousState,
      list,
      group,
    };
  },
  [DATA_ACTIONS.PUSH_APPLIST]: (
    previousState: ReducerData,
    action: ReduxActionData<PushData>,
  ) => {
    const list = { ...previousState.list };
    const pushData = action.payload;

    if (pushData) {
      if (pushData.data.status.toLowerCase() === 'close') {
        delete list[pushData.data.appId];
      } else {
        list[pushData.data.appId] = pushData.data;
      }
    }

    return {
      ...previousState,
      list,
    };
  },
};

const dataState: (
  state: ReducerData,
  action: ReduxActionData<any>,
) => ReducerData = (state = initialState, action: ReduxActionData<any>) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default dataState;
