import React, { useState } from 'react';

interface RadioOptionProps {
  id: string;
  name: string;
  value: string;
  label: string;
  color: string;
  checked: boolean;
  onChange: (value: string) => void;
}

const RadioOption = ({
  id,
  name,
  value,
  label,
  color,
  checked,
  onChange,
}: RadioOptionProps) => {
  const handleClick = () => {
    onChange(value);
  };

  return (
    <div
      className="relative inline-flex cursor-pointer items-center pr-5"
      onClick={handleClick}
    >
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        className="hidden"
        checked={checked}
        onChange={() => {}}
      />
      <div className={`mr-2 h-5 w-5 rounded-full ${color}`}>
        {checked && (
          <svg
            className="h-5 w-5 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
        )}
      </div>
      <label htmlFor={id} className="text-sm">
        {label}
      </label>
    </div>
  );
};

interface RadioGroupProps {
  options: { value: string; label: string; color: string }[];
  name: string;
  defaultSelected?: string;
  onChange: (value: string) => void;
}

const RadioGroup = ({
  options,
  name,
  defaultSelected,
  onChange,
}: RadioGroupProps) => {
  const [selected, setSelected] = useState(defaultSelected);

  const handleChange = (value: string) => {
    setSelected(value);
    onChange(value);
  };

  return (
    <div>
      {options.map((option) => (
        <RadioOption
          key={option.value}
          id={option.value}
          name={name}
          value={option.value}
          label={option.label}
          color={option.color}
          checked={selected === option.value}
          onChange={handleChange}
        />
      ))}
    </div>
  );
};

export default RadioGroup;
