import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useForm, SubmitHandler } from 'react-hook-form';

import Spinner from './Spinner';
import { getConfigList, updateConfigList } from '../resources/api-constants';
import { ConfigData } from '../types';

// const configData: ConfigData = {
//   emails: 'xxxx@126.com',
//   warn: '30',
//   danger: '60',
//   info: '1',
// };

// prop-types
interface Props {
  isOpen: boolean;
  defaultValues?: ConfigData;
  onClose: (value?: boolean) => void;
}

const SettingsModal = ({ isOpen, defaultValues, onClose }: Props) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  const readOnly = user && user.userType !== 1;
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ConfigData>({ defaultValues });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await getConfigList();
        res.data && reset(res.data);
        // reset(configData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchData();
  }, [reset]);

  const onSubmit: SubmitHandler<ConfigData> = async (data) => {
    try {
      setError(false);
      setSubmitting(true);
      await updateConfigList(data);
      setSubmitting(false);
      onClose();
    } catch (err) {
      setError(true);
      setSubmitting(false);
    }
  };

  const closeModal = () => {
    setSubmitting(false);
    setError(false);
    onClose();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h2"
                  className="text-lg font-bold leading-6 text-gray-900"
                >
                  Settings
                </Dialog.Title>
                {loading && <Spinner />}
                {!loading && (
                  <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                    {error && (
                      <div className="mb-3 rounded-full bg-red-100 py-2 text-center text-red-600">
                        An error occurred, please try again.
                      </div>
                    )}
                    <div className="mb-4">
                      <label
                        htmlFor="emails"
                        className="mb-2 block text-sm font-medium text-gray-900"
                      >
                        Recipients&apos; emails
                        <span className="ml-1 text-xs text-gray-500">
                          (multiple &apos;;&apos; separated)
                        </span>
                      </label>
                      <input
                        type="text"
                        id="emails"
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                        {...register('emails', { required: true })}
                        readOnly={readOnly}
                      />
                      {errors.emails && (
                        <p className="mt-2 text-sm text-red-600">
                          This field is required
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="info"
                        className="mb-2 block text-sm font-medium text-green-600"
                      >
                        Working Fine - With Updates (in second)
                      </label>
                      <input
                        type="number"
                        id="info"
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                        {...register('info', { required: true })}
                        readOnly={readOnly}
                      />
                      {errors.info && (
                        <p className="mt-2 text-sm text-red-600">
                          This field is required
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="warn"
                        className="mb-2 block text-sm font-medium text-amber-500"
                      >
                        Unstable - Without Updates (in second)
                      </label>
                      <input
                        type="number"
                        id="warn"
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                        {...register('warn', { required: true })}
                        readOnly={readOnly}
                      />
                      {errors.warn && (
                        <p className="mt-2 text-sm text-red-600">
                          This field is required
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="danger"
                        className="mb-2 block text-sm font-medium text-red-600"
                      >
                        Not Working - Without Updates (in second)
                      </label>
                      <input
                        type="number"
                        id="danger"
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                        {...register('danger', { required: true })}
                        readOnly={readOnly}
                      />
                      {errors.danger && (
                        <p className="mt-2 text-sm text-gray-500">
                          This field is required
                        </p>
                      )}
                    </div>

                    <div className="mt-4 text-right">
                      <button
                        type="submit"
                        disabled={submitting || readOnly}
                        className="mr-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:opacity-50"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="rounded-lg border border-gray-200 bg-white py-2.5 px-5 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SettingsModal;
