const config = {
  env: process.env.NODE_ENV || 'development',
  api: {
     url: process.env.REACT_APP_API_URL || window.location.href,
  },
  socket: {
    url:
      process.env.REACT_APP_WEBSOCKET_URL ||
      window.location.href.replace('http', 'ws'),
  },
};

export default config;
