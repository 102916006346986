import { AppsData, PushData, ReduxAction } from '../../types';

export enum DATA_ACTIONS {
  SET_APPLIST = 'dataActions/setAppList',
  PUSH_APPLIST = 'dataActions/pushAppList',
}

export const setDataAppList: ReduxAction<AppsData> = (data: AppsData) => {
  return {
    type: DATA_ACTIONS.SET_APPLIST,
    payload: data,
  };
};

export const pushDataAppList: ReduxAction<PushData> = (data: PushData) => {
  return {
    type: DATA_ACTIONS.PUSH_APPLIST,
    payload: data,
  };
};
