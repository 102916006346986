import React, { useEffect, useState, useCallback } from 'react';
import clsx from 'clsx';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { utcToZonedTime, format } from 'date-fns-tz';
import { Tab } from '@headlessui/react';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';

import config from '../config';
import Spinner from '../components/Spinner';
import SettingsModal from '../components/SettingsModal';
import { State } from '../types';
import { getAppList } from '../resources/api-constants';
import { setDataAppList, pushDataAppList } from '../store/actions/data';
import Login from '../components/Login';
import RadioGroup from '../components/RadioGroup';
import Filters from '../components/Filters';

// const appData = {
//   data: [
//     {
//       appId: '1',
//       name: 'ZD Server IP:192.168.0.1',
//       group: 'ZD-CB Api Connection',
//       platform: 'zd',
//       timestamp: 1679327010,
//       status: 'danger',
//       sport: null,
//       league: null,
//       game: null,
//       zdId: null,
//     },
//     {
//       appId: '2',
//       name: '2000074320VRFTB2023',
//       group: 'Waverdat-ZD Feed Connection',
//       platform: 'zd',
//       timestamp: 1679504550,
//       status: 'danger',
//       sport: null,
//       league: null,
//       game: null,
//       zdId: null,
//     },
//     {
//       appId: '3',
//       name: 'ZD Server IP:172.31.38.132',
//       group: 'ZD Backoffice Connection',
//       platform: 'zd',
//       timestamp: 1678807433,
//       status: 'danger',
//       sport: null,
//       league: null,
//       game: null,
//       zdId: null,
//     },
//     {
//       appId: '4',
//       name: 'ZD Server IP:172.31.39.40',
//       group: 'ZD-CB Api Connection',
//       platform: 'zd',
//       timestamp: 1678807433,
//       status: 'danger',
//       sport: null,
//       league: null,
//       game: null,
//       zdId: null,
//     },
//     {
//       appId: '5',
//       name: 'ZD Server IP:172.31.38.132',
//       group: 'Waverdat-ZD Settlement Connection',
//       platform: 'zd',
//       timestamp: 1678807433,
//       status: 'danger',
//       sport: null,
//       league: null,
//       game: null,
//       zdId: null,
//     },
//     {
//       appId: '6',
//       name: 'ZD Server IP:172.31.39.40',
//       group: 'Waverdat-ZD Settlement Connection',
//       platform: 'zd',
//       timestamp: 1678807433,
//       status: 'danger',
//       sport: null,
//       league: null,
//       game: null,
//       zdId: null,
//     },
//     {
//       appId: '10',
//       name: 'YG Server IP:172.31.38.132',
//       group: 'YG-CB Api Connection',
//       platform: 'yg',
//       timestamp: 1678809359,
//       status: 'danger',
//       sport: null,
//       league: null,
//       game: null,
//       zdId: null,
//     },
//     {
//       appId: '11',
//       name: 'YG Server IP:172.31.38.132',
//       group: 'YG Backoffice Connection',
//       platform: 'yg',
//       timestamp: 1678807433,
//       status: 'danger',
//       sport: null,
//       league: null,
//       game: null,
//       zdId: null,
//     },
//     {
//       appId: '12',
//       name: 'YG Server IP:172.31.38.132',
//       group: 'Waverdat-YG Settlement Connection',
//       platform: 'yg',
//       timestamp: 1678807433,
//       status: 'danger',
//       sport: null,
//       league: null,
//       game: null,
//       zdId: null,
//     },
//     {
//       appId: '13',
//       name: '1000074320VRBHT2023',
//       group: 'Waverdat-YG Feed Connection',
//       platform: 'yg',
//       timestamp: 1692713215,
//       status: 'danger',
//       sport: 'Cricket',
//       league: 'Lanka Premier League',
//       game: 'Gamblers SC vs Friendship CC',
//       zdId: '1',
//     },
//     {
//       appId: '14',
//       name: 'ZD Server IP:192.168.0.123',
//       group: 'ZD-CB Api Connection',
//       platform: 'zd',
//       timestamp: 1688135908,
//       status: 'danger',
//       sport: null,
//       league: null,
//       game: null,
//       zdId: null,
//     },
//   ],
//   group: [
//     {
//       platform: 'yg',
//       group: 'Waverdat-YG Feed Connection',
//     },
//     {
//       platform: 'yg',
//       group: 'Waverdat-YG Settlement Connection',
//     },
//     {
//       platform: 'yg',
//       group: 'YG Backoffice Connection',
//     },
//     {
//       platform: 'yg',
//       group: 'YG-CB Api Connection',
//     },
//     {
//       platform: 'zd',
//       group: 'Waverdat-ZD Feed Connection',
//     },
//     {
//       platform: 'zd',
//       group: 'Waverdat-ZD Settlement Connection',
//     },
//     {
//       platform: 'zd',
//       group: 'ZD Backoffice Connection',
//     },
//     {
//       platform: 'zd',
//       group: 'ZD-CB Api Connection',
//     },
//   ],
// };

const HomePage = () => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [lastUpdated, setLastUpdated] = useState(0);
  const [status, setStatus] = useState('all');
  const [sport, setSport] = useState('all');
  const [search, setSearch] = useState('');

  const list = useSelector((state: State) =>
    Object.values(state.data.list || {}),
  );
  const group = useSelector((state: State) => state.data.group || []);

  const platforms = Array.from(
    new Set(group.map(({ platform }) => platform)),
  ).sort((a, b) => (a > b ? 1 : a === b ? -1 : 0));

  const fetchAppList = useCallback(async () => {
    try {
      if (!status && !sport && !search) {
        setLoading(true);
      }
      const res = await getAppList(status, sport, search);
      dispatch(setDataAppList(res.data));
      // setTimeout(() => {
      //   console.log(status, sport, search);
      //   dispatch(setDataAppList(appData));
      // }, 1000);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [dispatch, status, sport, search]);

  useEffect(() => {
    fetchAppList();
  }, [fetchAppList]);

  const webSocketURL =
    config.socket.url +
    'websocket' +
    (user && user.userId ? '/' + user.userId : '');
  const { lastMessage, readyState } = useWebSocket(webSocketURL, {
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: (attemptNumber) =>
      Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  useEffect(() => {
    if (lastMessage !== null) {
      const pushData = JSON.parse(lastMessage.data);
      setLastUpdated(pushData.timestamp);
      dispatch(pushDataAppList(pushData));
    }
  }, [dispatch, lastMessage]);

  const getInfoStatus = (platform: string): boolean => {
    const a = list.filter(
      (a) =>
        a.platform === platform &&
        (a.status === 'warn' || a.status === 'danger'),
    ).length;
    return a === 0;
  };

  const getWarnStatus = (platform: string): boolean => {
    const a = list.filter(
      (a) => a.platform === platform && a.status === 'danger',
    ).length;
    return a > 0;
  };

  const getDangerStatus = (platform: string): boolean => {
    let b = false;

    const groups = Array.from(
      new Set(
        list.filter((a) => a.platform === platform).map(({ group }) => group),
      ),
    );

    for (let i = 0; i < groups.length; i++) {
      const a = list.filter(
        (a) =>
          a.platform === platform &&
          a.group === groups[i] &&
          (a.status === 'info' || a.status === 'warn'),
      ).length;
      if (a === 0) {
        b = true;
        break;
      }
    }

    return b;
  };

  const onChangeStatus = async (value: string) => {
    setStatus(value);
  };

  const onSportChange = async (value: string) => {
    setSport(value);
  };

  const onSearchChange = async (value: string) => {
    setSearch(value);
  };

  return (
    <div className="relative m-auto">
      <Helmet title="Health Status" />
      {!user && <Login />}
      {user && (
        <div className="p-3">
          {loading && <Spinner />}
          {!loading && (
            <>
              {group.length ? (
                <>
                  <Tab.Group
                    selectedIndex={selectedIndex}
                    onChange={setSelectedIndex}
                  >
                    <div className="mb-3 flex justify-center rounded-full bg-slate-200 py-2 text-center text-slate-600 md:absolute md:right-11 md:top-1.5 md:mb-0 md:bg-transparent md:py-2.5">
                      {readyState !== ReadyState.OPEN && (
                        <span className="mx-4 py-2">{connectionStatus}</span>
                      )}
                      {readyState === ReadyState.OPEN && lastUpdated > 0 && (
                        <div className="text-sm">
                          <div className="mx-4">
                            <span className="mr-2 text-red-900">
                              Last Updated (in UTC):
                            </span>
                            {format(
                              utcToZonedTime(lastUpdated * 1000, 'UTC'),
                              'yyyy-MM-dd HH:mm:ss',
                              { timeZone: 'UTC' },
                            )}
                          </div>
                          <div className="mx-4">
                            <span className="mr-2 text-red-900">
                              Last Updated (in HKT):
                            </span>
                            {format(
                              utcToZonedTime(
                                lastUpdated * 1000,
                                'Asia/Hong_Kong',
                              ),
                              'yyyy-MM-dd HH:mm:ss',
                              { timeZone: 'Asia/Hong_Kong' },
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <Tab.List className="relative border-b-2 border-slate-300">
                      {platforms.map((platform) => (
                        <Tab
                          key={platform}
                          className={({ selected }) =>
                            clsx(
                              'mr-2 w-32 rounded-t-lg py-3 uppercase hover:bg-slate-300 hover:text-slate-600',
                              selected
                                ? 'bg-slate-300 text-slate-600'
                                : 'bg-slate-100 text-slate-500',
                            )
                          }
                        >
                          {platform}
                          {getInfoStatus(platform) && (
                            <i className="ml-1 mb-[1.5px] inline-block h-2 w-2 rounded-full bg-green-600" />
                          )}
                          {!getDangerStatus(platform) &&
                            getWarnStatus(platform) && (
                              <i className="ml-1 mb-[1.5px] inline-block h-2 w-2 rounded-full bg-amber-500" />
                            )}
                          {getDangerStatus(platform) && (
                            <i className="ml-1 mb-[1.5px] inline-block h-2 w-2 rounded-full bg-red-600" />
                          )}
                        </Tab>
                      ))}
                      <div
                        className="absolute right-1 bottom-2.5 flex items-center justify-between text-slate-600"
                        onClick={() => setIsOpen(true)}
                      >
                        <Cog6ToothIcon
                          className="h-7 w-7 cursor-pointer"
                          aria-hidden="true"
                        />
                      </div>
                    </Tab.List>
                    <div className="py-3 text-sm text-slate-500">
                      <RadioGroup
                        options={[
                          { value: 'all', label: 'All', color: 'bg-blue-600' },
                          {
                            value: 'info',
                            label: 'Working fine',
                            color: 'bg-green-600',
                          },
                          {
                            value: 'warn',
                            label: 'Unstable',
                            color: 'bg-amber-500',
                          },
                          {
                            value: 'danger',
                            label: 'Not Working',
                            color: 'bg-red-600',
                          },
                        ]}
                        name="status"
                        defaultSelected="all"
                        onChange={onChangeStatus}
                      />
                    </div>
                    <Tab.Panels>
                      {platforms.map((platform) => (
                        <Tab.Panel
                          key={platform}
                          className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4"
                        >
                          {Array.from(
                            new Set(
                              group
                                .filter((a) => a.platform === platform)
                                .map(({ group }) => group),
                            ),
                          )
                            .sort((a, b) => (a > b ? 1 : a === b ? 0 : -1))
                            .map((group) => (
                              <div
                                key={group}
                                className="rounded-lg bg-slate-200 px-4 pb-5"
                              >
                                <h3 className="pt-3 font-semibold capitalize">
                                  {group}
                                </h3>
                                {group.includes('Feed Connection') ? (
                                  <>
                                    <Filters
                                      defaultSport={sport}
                                      defaultSearch={search}
                                      onSportChange={onSportChange}
                                      onSearchChange={onSearchChange}
                                    />
                                    {list
                                      .filter(
                                        (a) =>
                                          a.platform === platform &&
                                          a.group === group,
                                      )
                                      .sort((a, b) =>
                                        a > b ? 1 : a === b ? 0 : -1,
                                      )
                                      .map(
                                        ({
                                          sport,
                                          league,
                                          game,
                                          name,
                                          zdId,
                                          status,
                                          matchTime,
                                        }) => (
                                          <div
                                            key={name}
                                            className={clsx(
                                              'mt-4 px-3 py-2 text-left text-sm text-white',
                                              {
                                                'bg-green-600':
                                                  status === 'info',
                                                'bg-amber-500':
                                                  status === 'warn',
                                                'bg-red-600':
                                                  status === 'danger',
                                              },
                                            )}
                                          >
                                            <div>Sports: {sport}</div>
                                            <div>League: {league}</div>
                                            <div>Game: {game}</div>
                                            <div>WD Feed ID: {name}</div>
                                            <div>ZD Event ID: {zdId}</div>
                                            <div>Match Time: {matchTime}</div>
                                          </div>
                                        ),
                                      )}
                                  </>
                                ) : (
                                  <>
                                    {list
                                      .filter(
                                        (a) =>
                                          a.platform === platform &&
                                          a.group === group,
                                      )
                                      .sort((a, b) =>
                                        a > b ? 1 : a === b ? 0 : -1,
                                      )
                                      .map(({ name, status }) => (
                                        <div
                                          key={name}
                                          className={clsx(
                                            'mt-4 px-3 py-2 text-center text-sm text-white',
                                            {
                                              'bg-green-600': status === 'info',
                                              'bg-amber-500': status === 'warn',
                                              'bg-red-600': status === 'danger',
                                            },
                                          )}
                                        >
                                          {name}
                                        </div>
                                      ))}
                                  </>
                                )}
                              </div>
                            ))}
                        </Tab.Panel>
                      ))}
                    </Tab.Panels>
                  </Tab.Group>
                </>
              ) : (
                <div className="text-center">No results found</div>
              )}
            </>
          )}
          <SettingsModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
        </div>
      )}
    </div>
  );
};

export default HomePage;
