import React, { useState, ChangeEvent } from 'react';

const sports = [
  { label: 'ALL', value: 'all' },
  { label: 'Cricket', value: 'LIVE' },
  // { label: 'Ecricket', value: 'ECRIC' },
  { label: 'Big Hitter', value: 'VRBHT' },
  { label: 'Hit or Miss', value: 'VRHOM' },
  { label: 'Quantum', value: 'QUANTUM' },
  { label: 'Virtual Kabaddi', value: 'VRKBD' },
  { label: 'Kabaddi', value: 'KABD' },
  { label: 'Virtual Football', value: 'VRFTB' },
];

interface Props {
  defaultSport: string;
  defaultSearch: string;
  onSportChange: (value: string) => Promise<void>;
  onSearchChange: (value: string) => Promise<void>;
}

const Filters = ({
  defaultSport,
  defaultSearch,
  onSportChange,
  onSearchChange,
}: Props) => {
  const [selectedSport, setSelectedSport] = useState(defaultSport || 'all');
  const [searchTerm, setSearchTerm] = useState(defaultSearch || '');

  const handleSportChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedSport(event.target.value);
    onSportChange(event.target.value);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClick = () => {
    onSearchChange(searchTerm);
  };

  return (
    <div className="mt-2 flex w-full items-center space-x-4">
      <select
        className="w-full rounded-md border border-gray-300 px-3 py-2"
        value={selectedSport}
        onChange={handleSportChange}
      >
        {sports.map((sport) => (
          <option key={sport.value} value={sport.value}>
            {sport.label}
          </option>
        ))}
      </select>
      <div className="relative w-full">
        <input
          className="w-full rounded-md border border-gray-300 px-3 py-2 pr-10"
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button
          title="Search"
          className="absolute right-2 top-1/2 -translate-y-1/2 transform"
          onClick={handleSearchClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-6 w-6 text-gray-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Filters;
